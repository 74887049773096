:root {
    --accent: #090;
    --main: #333;
    --error: #dc3545;
    --border: #cccccc;
}

img {
    max-width: 100%;
}

.form-group {
    margin-bottom: 10px;
}

.form-error {
    color: var(--error);
    font-size: 16px;
    font-style: italic;
}

main {
    display: flex;
}

.main-sildebar {
    display: flex;
    flex-shrink: 0;
    width: 270px;
    padding: 16px;
    color: #fff !important;
    background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
    flex-direction: column;
    position: fixed;
    height: 100%;
    overflow-y: auto;
}

.nav em {
    margin-right: 5px;
}

.main-content {
    width: calc(100% - 270px);
    margin-left: 270px;
}

.main-title {
    padding: 16px;
    border-bottom: 1px solid var(--border);
}

.main-title h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 4px;
}

.btn+.btn {
    margin-left: 5px;
}

.control-panel {
    margin: 16px 0;
    display: flex;
    align-items: center;
}

.control-panel .btn:not(:last-child) {
    margin-right: 10px;
}

.control-panel .btn-group .btn {
    margin-left: 0;
    margin-right: 0;
}

.control-panel .search-panel {
    max-width: 600px;
}

ul.no-bullets {
    list-style: none;
    padding-left: 0;
}

ul.list-group {
    border-bottom: none;
    border-radius: 0;
    border-top: none;
}

.toasts-position {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 120px;
    min-width: 300px;
}

.toast {
    background-color: var(--bs-toast-header-bg);
    border: var(--bs-toast-header-bg);
    ;
}

.toast-body {
    background-color: var(--bs-toast-header-bg);
}

.toast-header {
    border-bottom: 1px solid #ca999e;
}

.tab-content {
    padding: 16px 16px;
    border: 1px solid #dee2e6;
    border-top: none;
}

.image-container {
    max-width: 300px;
    position: relative;
}

.image-container .tools {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.image-container:hover .tools {
    display: block;
}

.images-editor {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

}

.images-editor .image-item {
    position: relative;
}

.images-editor img {
    max-height: 130px;
    width: auto;
    margin: 0 5px 5px 0;
}

.images-editor .tools {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
}

.images-editor .image-item:hover .tools {
    display: block;
}

.form-control.w100 {
    max-width: 140px;
}

.toggle-menu-link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 36px;
}

.main-sildebar.collapsed {
    width: 50px;
    overflow-x: hidden;
    padding: 16px 10px;
}

.main-sildebar.collapsed .nav-link em {
    font-size: 1.5rem;
}

.main-sildebar.collapsed .nav-link span {
    display: none;
}

.main-sildebar.collapsed .toggle-menu-link span {
    display: none;
}

.main-sildebar.collapsed .account-menu-item strong {
    display: none;
}

.main-sildebar.collapsed .dropdown-item span {
    display: none;
}

.main-sildebar.collapsed .nav-pills .nav-link {
    padding: 10px 0;
}

.main-sildebar.collapsed + .main-content {
    width: calc(100% - 50px);
    margin-left: 50px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-bg) !important;
    background-color: transparent;
}

.main-sildebar.collapsed  .toggle-menu-link {
    justify-content: center;
}