.card.card-check {
    color: #fff;
}

.card.card-check li.list-group-item {
    background: transparent;
    color: #fff;
}

@media (min-width: 992px) {
    .card.card-check {
        display: flex;
        flex-direction: row;
    }

    .card.card-check .card-body {
        width: 60%;
    }

    .card.card-check>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    /* .card.card-check>.list-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card.card-check>.list-group li {
        border: 0;
    } */
}