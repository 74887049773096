@media (min-width: 992px) {
    .card.card-license {
        display: flex;
        flex-direction: row;
    }

    .card.card-license .card-body {
        width: 60%;
    }

    .card.card-license>.list-group {
        border-left: 1px solid var(--border);
        min-width: 40%;
        max-width: 40%;
    }

    /* .card.card-license>.list-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card.card-license>.list-group li {
        border: 0;
    } */
}